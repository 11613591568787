function debounce(func, timeout = 800) {
    // call the function passed as argument only after a set amount of time has passed
    // prevent launching an action when the user is still actively changing the value
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export const oscar = ((o) => {
    o.search = {
        init: function () {
            o.search.initFacetWidgets();
        },
        initFacetWidgets: () => {
            // Bind events to facet checkboxes
            const facet_checkboxes = Array.from(
                document.getElementsByClassName("facet_checkbox")
            );
            facet_checkboxes.forEach((facet_checkbox) => {
                facet_checkbox.addEventListener("change", (event) => {
                    window.location.href = event.target.nextElementSibling.value;
                });
            });
        },
    };

    o.checkout = {
        initGateway: () => {
            Forms.initToggleInput("gateway-content-switch", "options", [
                "anonymous",
                "new",
                "existing",
            ]);
        },
        initShippingAddress: () => {
            const addressValues = ["newAddress", "addressBook"];
            Forms.initToggleInput(
                "address-type-switch",
                "shipping-address_type",
                addressValues
            );
            Forms.initToggleInput(
                "address-type-switch",
                "billing-address_type",
                addressValues
            );

            document
                .getElementById("id_billing_same_shipping")
                .addEventListener("change", (event) => {
                    const formBlock = document.getElementById("billing-block");
                    formBlock.classList.toggle("hidden");
                });

            const phone_international_prefix = {
                RE: "+262",
                NC: "+687",
                MQ: "+596",
                MF: "+590",
                GF: "+594",
                GP: "+590",
            };

            function setShippingPhonePrefix(country) {
                const phoneInput = document.getElementById("id_shipping-phone_number");
                if (phoneInput.value.length <= 4) {
                    phoneInput.value = phone_international_prefix[country];
                }
            }

            const shippingCountryInput = document.getElementById(
                "id_shipping-country"
            );
            setShippingPhonePrefix(shippingCountryInput.value);
            shippingCountryInput.addEventListener("change", (event) => {
                setShippingPhonePrefix(event.target.value);
            });
        },
    };

    o.customer = {
        initOrderDetail: () => {
            function setCurrentOrder(label) {
                const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                label.dispatchEvent(clickEvent);
                label.classList.add("selected-tab");
            }

            const selectOrder = document.getElementById("order_select");

            setCurrentOrder(selectOrder.children[0]);

            const labels = Array.from(selectOrder.children);
            labels.forEach((label) => {
                label.addEventListener("click", (e) => {
                    labels.forEach((l) => l.classList.remove("selected-tab"));
                    label.classList.add("selected-tab");
                });
            });
        },
    };

    o.notifications = {
        init: function () {
            const buttons = Array.from(
                document.getElementsByClassName("notif-update")
            );
            buttons.forEach((button, index) => {
                button.addEventListener("click", (event) => {
                    const checkboxTarget = button.getAttribute("data-target");
                    const typeTarget = button.getAttribute("data-type");
                    const checkbox = document.getElementById(checkboxTarget);
                    checkbox.checked = true;
                    const submitTypeInput = document.getElementById(
                        `action-${typeTarget}`
                    );
                    submitTypeInput.checked = true;
                    submitTypeInput.form.submit();
                });
            });
        },
    };

    o.productDetail = {
        updateSelectableAttributes: (selectionAttrUrl, attributes, index) => {
            const formValues = attributes.reduce((acc, elt) => {
                acc[elt.name] = elt.value;
                return acc;
            }, {});
            fetch(`${selectionAttrUrl}?${new URLSearchParams(formValues)}`, {
                method: "GET",
            })
                .then((response) => response.json())
                .then((data) => {
                    attributes
                        .filter((value, i) => i !== index)
                        .forEach((update_attr) => {
                            for (const option of update_attr) {
                                let selectable = data[update_attr.name][option.label];
                                if (selectable === undefined) {
                                    selectable = true;
                                }
                                option.disabled = !selectable;
                            }
                        });
                });
        },

        initAddToBasket: (childProductSelected = false) => {
            const addToBasketForm = document.getElementById("add_to_basket_form");
            const selectionAttrUrl = addToBasketForm.getAttribute("selectionAttrUrl");
            const attributes = Array.from(
                document.getElementsByClassName("attribute-select")
            );
            attributes.forEach((attr, index) => {
                attr.addEventListener("change", (event) => {
                    if (attributes.every((elt) => elt.value) || childProductSelected) {
                        addToBasketForm.method = "get";
                        addToBasketForm.action = document.location;
                        const inputs = Array.from(
                            addToBasketForm.getElementsByTagName("input")
                        );

                        inputs.forEach((input) => {
                            if (input.type === "hidden") {
                                input.disabled = true;
                            }
                        });
                        addToBasketForm.submit();
                        return;
                    }
                    o.productDetail.updateSelectableAttributes(
                        selectionAttrUrl,
                        attributes,
                        index
                    );
                });
            });
            if (attributes.some((elt) => elt.value)) {
                o.productDetail.updateSelectableAttributes(
                    selectionAttrUrl,
                    attributes,
                    -1
                );
            }
        },
    };

    return o;
})({});

export const Forms = (() => {
    return {
        init: () => {
            Forms.initAutoSubmit();
            Forms.initSubmitOnce();
            Forms.initToggleRadio();
        },
        initAutoSubmit: () => {
            // set class on a field to automatically submit the associated form if that field change value
            const fields = Array.from(document.getElementsByClassName("auto-submit"));
            fields.forEach((field, index) => {
                field.addEventListener("change", (event) => {
                    event.target.form.submit();
                });
            });
        },
        initSubmitOnce: () => {
            // set class on a form so that it can only ever be submitted once
            const forms = Array.from(document.getElementsByClassName("submit-once"));
            forms.forEach((form, index) => {
                form.addEventListener("submit", (event) => {
                    // ignore invalid form, the user should be able to click again after fixing errors
                    if (!event.target.checkValidity()) {
                        return;
                    }
                    if (event.target.hasAttribute("locked")) {
                        event.preventDefault();
                        return;
                    }
                    event.target.setAttribute("locked", "");
                    const buttons = Array.from(
                        event.target.getElementsByTagName("button")
                    );

                    buttons.forEach((button) => {
                        if (button.type === "submit") {
                            button.disabled = true;
                            button.classList.add("cursor-wait");
                            const loadingText = button.getAttribute("data-loading-text");
                            if (loadingText) {
                                button.innerHTML = loadingText;
                                button.classList.add("!bg-dm-primary-light", "!text-white");
                            }
                        }
                    });
                });
            });
        },
        initToggleInput: (classSelector, inputName, values, extraFunction) => {
            // For a class, get all inputs (radiobutton) with given name and toggle div with corresponding ID
            [...document.getElementsByClassName(classSelector)]
                .filter((item) => {
                    return item.nodeName === "INPUT" && item.name === inputName;
                })
                .forEach((radioInput) => {
                    radioInput.addEventListener("change", (event) => {
                        document
                            .getElementById(`${inputName}-${event.target.value}`)
                            .classList.remove("hidden");
                        values
                            .filter((value) => value !== event.target.value)
                            .forEach((value) => {
                                document
                                    .getElementById(`${inputName}-${value}`)
                                    .classList.add("hidden");
                            });
                        if (extraFunction != null) {
                            extraFunction(event);
                        }
                    });
                });
        },
        initToggleRadio: () => {
            // set class on a radio so that it can be unselected
            const radios = Array.from(document.getElementsByClassName("toggle-radio"));
            radios.forEach((radio, index) => {
                radio.nextElementSibling.addEventListener('mouseup', (evt) => {
                    if (radio.checked) {
                        setTimeout(() => {
                            radio.checked = false;
                        })
                    }
                });
            });
        },
    };
})();

export const SlidingMenuAnimations = (function () {
    return {
        leftToRight: "l-to-r",
        rightToLeft: "r-to-l",
        /**
         * sliding menu initialization
         * @param menuId id of root div of sliding menu that is translated
         * @param openBtnId
         * @param hideBtnId
         * @param direction direction of translation can be "r-to-l" or "l-to-r", anything else throws an error
         */
        init: function (
            menuId,
            openBtnId,
            hideBtnId,
            direction = this.rightToLeft
        ) {
            const menu = document.getElementById(menuId);
            const openBtn = document.getElementById(openBtnId);
            const hideBtn = document.getElementById(hideBtnId);

            // default value covers "r-to-l" case
            let translation = "translate-x-full";
            if (direction === this.leftToRight) {
                translation = "-" + translation;
            } else if (direction !== this.rightToLeft) {
                throw new Error(
                    "SlidingMenuAnimations.init(menu, direction) direction argument can only have the valus 'l-to-r' or 'r-to-l'"
                );
            }

            const toggleMenu = () => {
                menu.classList.toggle(translation);
            };

            if (openBtn) {
                openBtn.addEventListener("click", toggleMenu);
            }

            if (hideBtn) {
                hideBtn.addEventListener("click", toggleMenu);
            }
        },
    };
})();

export const CategoryTreeAnimations = (function () {
    return {
        /**
         * mobile nav menu
         */
        catTree: null,
        /**
         * widescreen nav menu
         */
        header: null,
        /**
         * entrypoint
         */
        init: function () {
            this.catTree = document.getElementById("category_tree");
            this.header = document.getElementById("header");
            this._init(this.catTree);
            this._init(this.header);

            // in header, make it so left-side categories expand towards the right and vice-versa
            const rootUl = this.header.children[1];
            const middle = Math.ceil(rootUl.children.length / 2);
            for (let i = 0; i < rootUl.children.length; i++) {
                if (i > middle) {
                    // right-side categories
                    rootUl.children[i].querySelectorAll(":scope ul").forEach((ul) => {
                        Array.from(ul.children).forEach((li) => {
                            li.classList.add("left");
                            li.parentElement.classList.add("left");
                        });
                    });
                } else {
                    // left-side categories
                    rootUl.children[i].querySelectorAll(":scope ul").forEach((ul) => {
                        Array.from(ul.children).forEach((li) => {
                            li.classList.add("right");
                            li.parentElement.classList.add("right");
                        });
                    });
                }
            }
        },

        /**
         * set event listeners for the top-most category, then call
         * for recursive listener setting down the category tree
         * @param rootElement
         * @private
         */
        _init(rootElement) {
            rootElement.addEventListener("click", (e) => {
                rootElement.querySelector(":scope > ul").classList.toggle("expand")
                this._close_non_parents(rootElement);
                e.stopPropagation();
            });
            document.addEventListener("click", (e) => {
                // close root menus when clicking outside the nav menu
                this._close_non_parents(rootElement, true);
            });
            const rootUl = rootElement.querySelector(":scope > ul");
            this._init_recursively(rootUl);
        },

        /**
         * recurses down the category tree setting the click event listener for every subcategory
         * @param ul
         * @private
         */
        _init_recursively: function (ul) {
            const lis = ul.querySelectorAll(":scope > .has-categories");
            lis.forEach((li) => {
                const childUl = li.querySelector(":scope ul");
                li.addEventListener("click", (e) => {
                    if (childUl.classList.toggle("expand")) {
                        this._find_and_close_non_parents(childUl);
                    }
                    childUl.parentElement
                        .querySelector(".material-icons")
                        .classList.toggle("rotate");
                    e.stopPropagation();
                });
                this._init_recursively(childUl);
            });
        },

        /**
         * walk up the DOM from clickedEl until reaching the root of the nav bar, flagging all HTMLElements with the
         * ".no-touch" class so they will be ignored when "closing" all menus
         * @param clickedEl
         * @private
         */
        _find_and_close_non_parents: function (clickedEl) {
            clickedEl.classList.add("no-touch");
            let nextEl = clickedEl;
            const hierarchyEls = [clickedEl];
            while (true) {
                nextEl = nextEl.parentElement;
                if (nextEl.id === "category_tree" || nextEl.id === "header") break;
                nextEl.classList.add("no-touch");
                hierarchyEls.push(nextEl);
            }

            this._close_non_parents(this.catTree);
            this._close_non_parents(this.header);
            hierarchyEls.forEach((el) => el.classList.remove("no-touch"));
        },

        /**
         * selects all children of root in order to reset their nav menu state
         * @param root
         * @param {boolean} [closeRoot=false] whether root itself should be closed
         * @private
         */
        _close_non_parents(root, closeRoot = false) {
            if (closeRoot) {
                root.querySelector(":scope > ul").classList.remove("expand");
            }
            // remove ".expand" from all non-flagged elements that are not the open mobile menu
            root
                .querySelectorAll(
                    ".expand:not(.expand.no-touch):not(#category_tree>ul)"
                )
                .forEach((el) => {
                    el.classList.remove("expand");
                    const icon = el.parentElement.querySelector(".material-icons");
                    if (icon) {
                        icon.classList.remove("rotate");
                    }
                });
        },
    };
})();


export const ScrollableDivButtons = (function () {
    return {
        init: function (containerId, leftButtonId, rightButtonId) {
            this.partnersContainer = document.getElementById(containerId);
            if (!this.partnersContainer) {
                return;
            }
            this.partnersArrowForward = document.getElementById(leftButtonId);
            this.partnersArrowBack = document.getElementById(rightButtonId);

            this.partnersContainer.onscroll = () => ScrollableDivButtons.hideScrollButtons(this);
            this.partnersArrowForward.onclick = () => {
                const scrolled = this.partnersContainer.scrollLeft + this.partnersContainer.getBoundingClientRect().width;
                let scrollAmount = 300;
                if (scrolled >= (this.partnersContainer.scrollWidth - 450)) {
                    // if the remaining space on the right will be (after the click scroll is applyed) less than 150px, add those px to the scroll amount
                    scrollAmount += 150;
                }
                this.partnersContainer.scrollBy({
                    left: scrollAmount,
                    behavior: 'smooth',
                });
            }
            this.partnersArrowBack.onclick = () => {
                let scrollAmount = 300;
                // if the remaining space on the left will be (after the click scroll is applyed) less than 150px, add those px to the scroll amount
                if (this.partnersContainer.scrollLeft <= 450) {
                    scrollAmount += 150;
                }
                this.partnersContainer.scrollBy({
                    left: -scrollAmount,
                    behavior: 'smooth',
                });
            }
            setTimeout(() => {
                this.hideScrollButtons(this);
            });
            return this;
        },

        hideScrollButtons: function (obj) {
            const scrollLeft = obj.partnersContainer.scrollLeft;
            const scrolled = Math.ceil(scrollLeft + obj.partnersContainer.getBoundingClientRect().width)
            // {# if we scrolled at the left make the left button disapear #}
            if (scrolled === this.partnersContainer.scrollWidth) {
                obj.partnersArrowForward.classList.add('opacity-0');
            } else {
                obj.partnersArrowForward.classList.remove('opacity-0');
            }
            // if we scrolled at the right (with epsilon of 150px), make the right button disapear
            if (Math.floor(scrollLeft) === 0) {
                obj.partnersArrowBack.classList.add('opacity-0');
            } else {
                obj.partnersArrowBack.classList.remove('opacity-0');
            }
        }
    };
})();
