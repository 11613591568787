import {Modal, ModalInstance, ModalOptions} from "flowbite";
import config from '../tailwind.config.js';


import resolveConfig = require("tailwindcss/resolveConfig");

const fullConfig = resolveConfig(config)

export class HeaderModal extends Modal {

    _handleOutsideClick(target: EventTarget) {
        if (this._targetEl.contains(target as Element)) {
            return;
        }
        this.hide();
    }

    _setupModalCloseEventListeners() {
        if (this._options.backdrop === 'dynamic') {
            this._clickOutsideEventListener = (ev: MouseEvent) => {
                this._handleOutsideClick(ev.target);
            };
            document.addEventListener(
                'click',
                this._clickOutsideEventListener,
                true
            );
        }

        this._keydownEventListener = (ev: KeyboardEvent) => {
            if (ev.key === 'Escape') {
                // @ts-ignore
                this._options.onPressEscape(this)
            }
        };
        document.body.addEventListener(
            'keydown',
            this._keydownEventListener,
            true
        );
    }

    _removeModalCloseEventListeners() {
        document.removeEventListener(
            'click',
            this._clickOutsideEventListener,
            true
        );
        document.body.removeEventListener(
            'keydown',
            this._keydownEventListener,
            true
        );
    }

    hide() {
        if (this.isVisible) {
            this._options.onHide(this);
            this._targetEl.removeAttribute('role');
            setTimeout(() => {
                this._targetEl.classList.add('hidden');
                this._targetEl.classList.remove('flex');
                this._targetEl.setAttribute('aria-hidden', 'true');
                this._targetEl.removeAttribute('aria-modal');

                this._destroyBackdropEl();
                this._isHidden = true;

                // re-apply body scroll
                document.body.classList.remove('overflow-hidden');

                if (this._options.closable) {
                    this._removeModalCloseEventListeners();
                }

            }, 200)

        }
    }
}


export function initHeaderModal() {
    const $universeList = document.getElementById("universe-list");
    const $modalEl = document.getElementById("menuModal");
    const $button = document.getElementById("menuModalOpenButton")
    const $modalContainer = document.getElementById("menuModalContainer");
    const $menuModalAnchor = document.getElementById("menuModalAnchor");

    const fixModalMaxHeight = () => {
        const {y} = $menuModalAnchor.getBoundingClientRect();
        $modalContainer.style.height = `calc(95vh - ${y}px)`;
    }


    const headerModal = new HeaderModal(
        $modalEl, {
            onShow: () => {
                fixModalMaxHeight();
                $button.classList.add('active');
                $universeList.classList.add('default-open');
                $universeList.classList.remove('root-selected');
            },
            onHide: () => {
                $button.classList.remove('active');
                $button.blur();
                document.forms['universe_selection_form'].universe_selection.value = "-1";
                document.forms['universe_selection_form'].root_selection.value = "-1";
            },
            // @ts-ignore
            onPressEscape: (modal: HeaderModal) => {
                // >md: hide modal
                if (window.matchMedia(`(min-width: ${fullConfig.theme.screens['md']})`).matches) {
                    modal.hide();
                    return;
                }
                // <md: hide if no universe selected, unselect it otherwise
                const universeSelectionValue = document.forms['universe_selection_form'].universe_selection.value
                if (universeSelectionValue === "-1" || universeSelectionValue === undefined) {
                    modal.hide();
                } else {
                    document.forms['universe_selection_form'].universe_selection.value = "-1";
                }
            }
        }
    )
    // // // show modal on click if exists based on id
    document.querySelectorAll('[data-modal-header-show]').forEach(($triggerEl) => {
        $triggerEl.addEventListener('click', () => {
            if (headerModal.isHidden()) {
                headerModal.show();
            }
        });
    });

    // allow user to unselect a root category
    document.querySelectorAll('[data-unselect-radio]').forEach(($triggerEl) => {
        $triggerEl.addEventListener('click', () => {
            const radio = document.getElementById($triggerEl.getAttribute('for')) as HTMLInputElement;
            if (radio.checked) {
                setTimeout(() => {
                    radio.checked = false;
                })
            }
        });
    });
    // // // hide modal on click if exists based on id
    document.querySelectorAll('[data-modal-header-hide]').forEach(($triggerEl) => {

        $triggerEl.addEventListener('click', () => {
            if (headerModal.isVisible) {
                headerModal.hide();
            }
        });

    });


    window.addEventListener('resize', () => {
        fixModalMaxHeight();
    });
    fixModalMaxHeight();

    (document.querySelector('form[name=universe_selection_form]') as HTMLFormElement).addEventListener('change',  (e) => {
        $universeList.classList.remove('default-open');
        if(document.forms['universe_selection_form'].universe_selection.value === "-1") {
            $universeList.classList.remove('root-selected');
        }
        else {
            $universeList.classList.add('root-selected');
        }
    })
}
