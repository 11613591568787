
import './scss/index.scss';
import './js/ui';
import 'flowbite';

import {Forms, ScrollableDivButtons, oscar, SlidingMenuAnimations} from './js/ui';
import {initHeaderModal} from "./js/header-modal";

window.addEventListener('DOMContentLoaded', () => {
    initHeaderModal();
    Forms.init();
    ScrollableDivButtons.init('partnersContainer', 'partnersArrowForward', 'partnersArrowBack');
});

window['oscar'] = oscar
window['SlidingMenuAnimations'] = SlidingMenuAnimations
