/**
 * This is a minimal config.
 *
 * If you need the full config, get it from here:
 * https://unpkg.com/browse/tailwindcss@latest/stubs/defaultConfig.stub.js
 */

module.exports = {
    theme: {
        extend: {
            colors: {
                "neutral": "#DEE8F5",
                "background": "#FAFBFC",
                "separator": "#F4F6FA",
                "primary": {
                    DEFAULT:"#14499A",
                    light: "#376FC4",
                },
                "secondary": "#FDC40A",
                "text": "#072149",
                "display-text": "#7A87BD",
            },
        },
    },
    plugins: [
        require("@tailwindcss/typography"),
        require("@tailwindcss/aspect-ratio"),
    ],
};
